@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;700&display=swap');

body {
  font-family: 'Roboto Mono', monospace;
}

.ant-layout {
  background-color: #FFF;

  .ant-input-affix-wrapper {
    background-color: #FAFAFA;

    .ant-input {
      background-color: #FAFAFA;
      padding: 7.6px 15px;
    }

    &:not(.ant-input-affix-wrapper-disabled):hover {
      border-color: #E8E9EB;
    }

    &:focus,
    &.ant-input-affix-wrapper-focused {
      border-color: #E7E7E7;
      box-shadow: 0 0 0 2px #E8E9EB;
    }
  }

  .ant-form-item-has-error :not(.ant-input-disabled).ant-input,
  .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper,
  .ant-form-item-has-error :not(.ant-input-disabled).ant-input:hover,
  .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:hover {
    background-color: #FAFAFA;
  }

  .ant-layout-sider {
    background-color: #FAFAFA;

    &.ant-layout-sider-collapsed {
      .title-logo {
        display: none;
      }

      .user-menu {
        .user-name {
          display: none;
        }
      }
    }

    .ant-layout-sider-trigger {
      background-color: #87CA3E;
      color: #FFF;
    }

    .logo {
      flex-direction: column;
      padding: 2em 0.5em 0.85em 0.5em;

      img {
        height: auto;
        max-width: 150px;
        width: 90%;
      }

      h3 {
        font-weight: bold;
      }
    }

    .user-menu {
      align-items: center;
      display: flex;
      width: 100%;

      span {
        color: #282828;

        &.anticon {
          flex: 0 0 auto;
          margin: 0!important;
          max-width: none;
          width: auto;
        }

        &.user-name {
          flex-basis: 0;
          flex-grow: 1;
          margin: 0!important;
          max-width: 100%;
          overflow: hidden;
          padding: 0 12px;
          text-overflow: ellipsis;
        }
      }
    }

    .ant-menu {
      background: #FAFAFA;
      border: 0;
    }
  }

  .site-layout {
    padding: 4em 2em;
  }

  .ant-table-wrapper {
    .ant-table.ant-table-small {
      font-size: 0.85em;
    }
  }
}

.ant-dropdown {
  > .ant-dropdown-arrow {
    border-top-color: #87CA3E;
    border-left-color: #87CA3E;
  }

  .ant-dropdown-menu-dark {
    background-color: #87CA3E;
  }
}

.ant-card {
  .ant-card-body {
    .custom-graphs {
      .custom-tooltip {
        border-collapse: collapse;
        padding: 0;
        width: 100%;

        th {
          padding-right: 10px;
          text-align: left;
        }

        td {
          text-align: right;
        }

        th, td {
          padding-top: 5px;
          padding-bottom: 5px;
        }
      }
    }
  }
}

.custom-tooltip {
  .ant-tooltip-inner {
    padding: 2px 4px;

    .ant-menu {
      .ant-menu-item {
        margin: 0;
      }
    }
  }
}

.ant-table-content {
  .ant-table-cell {
    .hover-under:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}