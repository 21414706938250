.login-screen {
  .wrapper {
    height: 100%;
    display: flex;
    flex-direction: row;

    div:first-child {
      background-color: #AAFF4E;
      width: 20%;
    }

    div {
      background-color: #87CA3E;
      width: 30%;
    }

    div:last-child {
      background-color: #64962E;
      width: 50%;
    }
  }

  .login-form {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .logo {
      flex-direction: column;

      img {
        max-width: 200px;
        width: 90%;
      }

      h3 {
        font-weight: bold;
      }
    }

    .spacer {
      display: flex;
      flex-direction: column;
      max-width: 400px;
      width: 85%;

      .logo {
        display: flex;
        justify-content: center;
        margin-bottom: 1em;
      }

      .recover {
        display: flex;
        font-size: 0.8em;
        justify-content: center;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .content-columns {
      flex-direction: column;

      > .ant-col-12 {
        min-width: 100%;
      }

      .login-form {
        flex: 1;
      }

      .wrapper {
        flex: 0 20px;
        height: auto;
      }
    }
  }
}
