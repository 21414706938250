.ant-table {
  .lbl-cancel {
    color: @volcano-5;
  }

  .lbl-valid {
    color: @green-6;
  }

  .lbl-for-cancel {
    color: @blue-6;

    span {
      margin-left: 3px;
    }
  }

  .lbl-error-cancel {
    color: @red-6;

    span {
      margin-left: 3px;
    }
  }

  .table-bag {
    border-radius: 4px;
    font-size: 11px;
    font-weight: normal;
    height: 20px;
    line-height: 20px;
    margin: 0 2px;
    min-width: 20px;
    padding: 2px 8px;
    white-space: nowrap;

    &.info {
      background-color: #F0F9FF;
      color: #1E74BB;
    }

    &.danger {
      background-color: #FFD0C9;
      color: #E8442D;
    }

    &.success {
      background-color: #87CA3E;
    }
  }

  .ant-table-tbody {
    .ant-table-cell {
      span.custom-rfc {
        display: inline-block;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
